/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Link } from 'gatsby';
import { SEO, Layout } from 'gatsby-theme-catalyst-core';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import PostFooter from './post-footer';
import kebabCase from 'lodash/kebabCase';
import { FaRegClock } from 'react-icons/fa';
//keywords={post.keywords && post.keywords.split(',')
//      image={post.socialImage.childImageSharp.seo}
//description={post.excerpt}
const Post = ({ data: { post }, previous, next }) => (
	<Layout>
		<SEO title={post.title}  />
		<article
			sx={{
				display: 'grid',
				gridTemplateRows: [
					'auto auto'
					// 'auto auto',
					// 'auto auto',
					// '150px 250px auto',
					// '250px 250px auto'
				],
				//	gridTemplateColumns: '1fr',
				justifyContent: 'center',
				width: '100vw',
				position: 'relative',
				left: 'calc(-50vw + 50%)',
				mt: [ 5, null, null, 6 ],
				variant: 'variants.postContainer'
			}}
		>
			{/* <Img
				imgStyle={{ objectFit: 'contain' }}
				sx={{
					//minWidth: "200px",
					//	gridColumn: '1',
					//	gridRow: [ '1', null, '1', null, null ],
					zIndex: 10,
					width: '80%',
					maxWidth: '200px',
					//maxWidth: 'maxPageWidth',
					mx: 'auto',
					variant: 'variants.postImage'
				}}
				fluid={post.featuredImage.fluid}
				alt={post.title}
			/> */}
			<div
				sx={{
					//gridColumn: '2 / 3',
					//gridRow: [ '2 / -1', null, '2 / -1', null, null ],
					zIndex: 20,
					bg: 'background'
				}}
			>
				<span
					sx={{
						color: 'textGray',
						fontSize: 1,
						ml: 3
					}}
				>
					{post.featuredImageCaption}
				</span>
				<div
					sx={{
						pt: 1,
						pb: 5,
						px: [ 3, null, 4, null, null ],
            maxWidth: '900px',
					}}
				>
					{/* <Styled.ul
            aria-label="Categories"
            sx={{
              display: "flex",
              listStyle: "none",
              justifyContent: "center",
              p: 0,
              m: 0,
            }}
          >
            {post.categories.map((category) => (
              <Styled.li
                key={kebabCase(category)}
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  letterSpacing: "wide",
                  color: "primary",
                  "::after": {
                    content: `"\\2022"`,
                    px: 2,
                  },
                  ":last-of-type": {
                    "::after": {
                      content: "none",
                    },
                  },
                  variant: "variants.postCategories",
                }}
              >
                <Styled.a as={Link} to={`/categories/${kebabCase(category)}/`}>
                  {category}
                </Styled.a>
              </Styled.li>
            ))}
          </Styled.ul> */}
					<Styled.h1
						sx={{
							textAlign: 'center',
							fontSize: [ 4, 5, null, null, null ],
							mt: 1,
							mb: 3,
							'::after': {
								display: 'block',
								content: '""',
								width: '100px',
								pt: 1,
								// borderBottomStyle: "solid",
								// borderBottomWidth: "4px",
								// borderBottomColor: "primary",
								margin: '0 auto'
							},
							variant: 'variants.postTitle'
						}}
					>
						{post.title}
					</Styled.h1>
					<Styled.p
						sx={{
							color: 'textGray',
							fontSize: 1,
							// textTransform: 'uppercase',
							letterSpacing: 'wider',
							textAlign: 'center',
							m: 0,
							a: {
								color: 'textGray',
								textDecoration: 'none',
								':hover': {
									textDecoration: 'underline'
								}
							},
							variant: 'variants.postMeta'
						}}
					>
						{post.date}
						{/* &bull;{" "} */}
						{/* <FaRegClock
              sx={{
                position: "relative",
                top: "0.125em",
              }}
            />{" "}
            {post.timeToRead} Min */}
					</Styled.p>
					hesdsfaf

					{/* <MDXRenderer>{post.body}</MDXRenderer> */}
					{/* <PostFooter {...{ previous, next }} /> */}
				</div>
			</div>
		</article>
	</Layout>
);

export default Post;
